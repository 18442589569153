<template>
   <section>
      <div class="container p-3 w-page">
         <div class="row align-items-center mb-4">
            <div class="col-3">
               <img :src="produto.fotoMarca == null ? '' : produto.fotoMarca" alt="logo" @error="logoError">
               <span ref="nomeMarca">{{ produto.nomeMarca }}</span>
            </div>
            <div class="col-9 text-end">
               <h6 class="mb-0"><strong>FICHA TÉCNICA</strong></h6>
               <span>Gerado em: {{ data.getDate().toString().padStart(2, "0") +'/'+ (data.getMonth() + 1).toString().padStart(2, "0") +'/'+ data.getFullYear() }}</span>
               <span> {{ data.getHours().toString().padStart(2, "0") +':'+ data.getMinutes().toString().padStart(2, "0") }}</span>
            </div>
         </div>

         <div class="table-content table-responsive">
            <table class="table border-0">
               <tbody>
                  <especificacao class="impressao" v-for="(especificacao, index) in produto.especificacoes" :key="index" :especificacao="especificacao" :index="index" :view="'Comparacao'" />
               </tbody>
            </table>
         </div>

         <div class="row align-items-center mb-4">
            <div class="col-12"><hr class="mb-2"></div>
            <div class="col-12 text-center">As especificações e descrições foram atualizadas na data em que este arquivo foi gerado, porém são sujeitas a mudanças sem prévio aviso. Para obter dados atualizados, consulte diretamente no site: www.ccmdobrasil.com.br.</div>
            <div class="col-12"><hr class="mt-2 mb-0"></div>
         </div>
      </div>
   </section>
</template>

<script>

import especificacao from '@/components/produtos/Especificacao.vue'

export default {
   name: 'EspecificacoesPDF',
   props: ['produto', 'variacao'],
   data: function () {
      return {
         data: new Date()
      }
   },
   components: {
      especificacao
   },
   methods: {
      logoError : function (e) {
         this.$axios.get(this.produto.fotoMarca).catch(() => {
            e.target.style.display = 'none'
            this.$refs.nomeMarca.style.display = 'block'
         })
      }
   }
}

</script>

<style scoped>

.row {
   margin: 0;
}

.row > * {
   padding: 0;
}

img {
   object-fit: contain;
}

.card {
   border-color: #0000;
   border-radius: 0;
}

.w-page {
   max-width: 1050px;
   width: max-content;
}

h5 strong {
   font-size: 18px !important;
}

*:not(h5) {
   font-size: 13px !important;
}

img {
   height: 36px;
   aspect-ratio: 3 / 1;
	object-fit: cover;
   object-position: center;
   display: block;
   position: relative;
}

img ~ span {
   display: none;
   font-size: 15px !important;
   background-color: #fff;
   text-overflow: ellipsis;
   white-space: nowrap;
   color: #ec2125;
   font-weight: 500;
}

</style>