<template>
   <div class="accordion-item">
      <h2 class="accordion-header" :id="'heading'+ index">
         <button class="accordion-button" :class="index != 0 ? 'collapsed' : ''" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+ index" :aria-expanded="index == 0 ? true : false" :aria-controls="'collapse'+ index">
            {{ duvida.titulo }}
         </button>
      </h2>
      <div :id="'collapse'+ index" class="accordion-collapse collapse" :class="index == 0 ? 'show' : ''" :aria-labelledby="'heading'+ index" data-bs-parent="#accordionExample">
         <div class="accordion-body">{{ duvida.descricao }}</div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Duvida',
   props: ['duvida', 'index']
}

</script>